import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 58",
  week: "Semana 9",
  day: "10",
  month: "may",
  monthNumber: "05",
  date: "2020-05-10",
  path: "/cronologia/semana-09#dia-10-may",
};
const Day58 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 1.880 nuevos positivos y 143 personas fallecidas.
          Permanecen hospitalizadas 122.730 personas, 465 más que el día
          anterior, y 2.214 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.328, lo que supone un aumento de 36 personas.{" "}
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,1 % y la tasa de recuperados del 51,4 %.{" "}
        </ModText>
        <ModText>
          El
          <strong>
            {" "}
            Ministerio de Transportes, Movilidad y Agenda Urbana (Mitma)
          </strong>{" "}
          ha publicado hoy una{" "}
          <InlineLink link="https://boe.es/boe/dias/2020/05/10/pdfs/BOE-A-2020-4912.pdf">
            Orden
          </InlineLink>{" "}
          para actualizar las condiciones del transporte de viajeros en el Plan
          de Transición hacia la Nueva Normalidad y fijar otros requisitos para
          garantizar una movilidad segura.
        </ModText>

        <ModImage
          src="/images/svg/03_abr_sector_transporte_todo_el_ancho.svg"
          alt="transporte de viajaros"
        />
        <ModText>
          Algunas de las <strong>medidas que contempla la Orden</strong> son las
          siguientes:{" "}
        </ModText>
        <ModDotList>
          En el ámbito del transporte urbano y periurbano, los servicios
          ferroviarios de Cercanías de competencia estatal irán aumentando su
          oferta progresivamente hasta recuperar el 100% de los servicios.
        </ModDotList>
        <ModDotList>
          En los vehículos de turismo particulares podrán viajar tantas personas
          como plazas tenga el vehículo, siempre que todas residan en el mismo
          domicilio, en cuyo caso además no será necesario el uso de mascarilla.
        </ModDotList>
        <ModDotList>
          Si no todas las personas conviven en el mismo domicilio, podrán
          desplazarse dos personas por cada fila de asientos, siempre que
          utilicen mascarilla y respeten la máxima distancia posible entre los
          ocupantes.
        </ModDotList>
        <ModDotList>
          En vehículos o vagones en los que todos los ocupantes deban ir
          sentados, el operador limitará la ocupación total de plazas de manera
          que los pasajeros tengan un asiento vacío contiguo que los separe de
          cualquier otro pasajero. No obstante, el operador podrá ubicar en
          asientos contiguos a personas que viajen juntas y convivan en el mismo
          domicilio, pudiendo resultar en este caso una ocupación superior.
        </ModDotList>
        <ModText>
          En todo caso, en los autobuses se mantendrá siempre vacía la fila
          posterior a la butaca del conductor.
        </ModText>
        <ModDotList>
          En el caso de que también exista la posibilidad de transportar
          viajeros de pie, se mantiene como referencia de ocupación dos viajeros
          por metro cuadrado en estas zonas y el 50% de los asientos
          disponibles.
        </ModDotList>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://boe.es/boe/dias/2020/05/10/pdfs/BOE-A-2020-4912.pdf"
            name="Condiciones del transporte de viajeros en el Plan de Transición"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day58;
